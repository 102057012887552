<template>
    <div class="row justify-content-center">
        <div class="col">
            <div class="text-center">
                <h1>防疫新生活訪客管制系統</h1>
            </div>
        </div>
        <div class="w-100"/>
        <div class="col-md-5">
            <b-form @submit.prevent="()=>{}">
                <b-form-group
                        description="學號、學生證號或是身份證字號"
                        label="尋找學生"
                        label-for="input-1"
                >
                    <b-input-group class="mt-3">
                        <template v-slot:append>
                            <b-button @click="queryStudent">送出</b-button>
                        </template>
                        <b-form-input autocomplete="off" ref="leftUp" @keypress.enter="queryStudent" v-model="fields.liftUp" trim/>
                    </b-input-group>
                </b-form-group>
                <b-alert v-bind:show="showStudentNotFound" variant="danger">查無此學生</b-alert>
            </b-form>
            <b-card
                    title="學生資訊"
            >
                <div v-show="studentInfo.name.length !== 0">
                    <b-card-text>
                        <ul class="list-unsealed">
                            <li>姓名：{{studentInfo.name}}</li>
                            <li>
                                住宿資訊
                                <ul class="list-unsealed">
                                    <li v-bind:key="index" v-for="(item, index) in studentInfo.liveInfo">
                                        {{item.list}}：{{item.room}}
                                    </li>
                                    <li v-show="studentInfo.liveInfo.length === 0">
                                        沒有資料
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </b-card-text>
                    <b-card-text>
                        <b-alert v-bind:show="!isLocationSet" variant="danger">請先設定地點</b-alert>
                        <b-alert v-model="showLeftAlert" variant="danger">訪客證件與學生證件重複</b-alert>
                        <b-form @submit.prevent="()=>{}">
                            <b-form-group
                                    description="請以身份證為主"
                                    label="新增訪客"
                                    label-for="guest"
                            >
                                <b-input-group class="mt-3">
                                    <template v-slot:append>
                                        <b-button v-bind:disabled="!isLocationSet" @click="addVisitor">送出</b-button>
                                    </template>
                                    <b-form-input autocomplete="off" ref="leftDown" v-bind:disabled="!isLocationSet" @keypress.enter="addVisitor" v-model="fields.liftDown" id="guest" trim/>
                                </b-input-group>
                            </b-form-group>
                        </b-form>
                    </b-card-text>
                    <b-card-text>
                        <b-pagination
                                v-model="metaData.liftTable.currentPage"
                                :total-rows="studentInfo.record.length"
                                per-page= "5"
                                aria-controls="stu-record"
                        />
                        <b-table
                                :sort-by.sync="metaData.liftTable.sortBy"
                                :sort-desc.sync="metaData.liftTable.sortDesc"
                                id="stu-record"
                                :current-page="metaData.liftTable.currentPage"
                                :items="studentInfo.record"
                                :fields="metaData.liftTable.fields"
                                per-page="5"
                        >
                            <template v-slot:cell(action)="row">
                                <b-link @click="findVisitor({uuid: row.item.uuid})" v-if="row.item.exit_time === ''">選取</b-link>
                            </template>
                        </b-table>
                    </b-card-text>
                </div>
                <b-card-text v-show="studentInfo.name.length === 0">
                    請搜尋學生的學號、學生證號、身份證號碼。
                </b-card-text>
            </b-card>
        </div>
        <div class="col-md-2">
            <b-form @submit.prevent="()=>{}">
                <b-form-group
                        description="訪客所留之證件號碼"
                        label="尋找訪客"
                        label-for="input-1"
                >
                    <b-input-group class="mt-3">
                        <template v-slot:append>
                            <b-button @click="findVisitor({keyWord: fields.middleUp})">送出</b-button>
                        </template>
                        <b-form-input autocomplete="off" @keypress.enter="findVisitor({keyWord: fields.middleUp})" v-model="fields.middleUp" trim/>
                    </b-input-group>
                </b-form-group>
            </b-form>
            <b-alert v-model="showMiddleVisitorNotFound" variant="danger">查無此人，或此人已離開</b-alert>
            <b-card
                    title="訪客資訊"
                    v-if="visitorInfo.uuid !== null"
            >
                <b-card-text>
                    <ul>
                        <li>誰的訪客：{{visitorInfo.who.name}}</li>
                        <li>進入地點：{{visitorInfo.location}}</li>
                        <li>進入時間：{{visitorInfo.enter_time}}</li>
                        <li>離開時間：{{visitorInfo.exit_time}}</li>
                    </ul>
                </b-card-text>
                <b-button block v-bind:disabled="visitorInfo.exit_time !== ''" variant="primary" @click="letVisitorExit()">離開</b-button>
            </b-card>
            <b-card v-else title="尋找訪客">
                <b-card-text>
                    本區塊用來登記訪客離開。
                </b-card-text>
            </b-card>
        </div>
        <div class="col">
            <b-form @submit.prevent="()=>{}">
                <b-form-group
                        description="務必選擇正確之場地，以利疫情調查。"
                        label="場地"
                        label-for="location"
                >
                    <b-input-group class="mt-3">
                        <b-form-select
                                id="location"
                                v-model="metaData.location"
                                :options="['一宿大廳', '一宿Ｂ棟', '二宿大廳', '交誼廳']"
                        />
                    </b-input-group>
                </b-form-group>
            </b-form>
            <b-alert v-bind:show="!isLocationSet" variant="danger">請先設定地點</b-alert>
            <b-button block variant="primary" @click="getStatus">更新(每10分鐘會自動更新)</b-button>
            <b-card
                    title="統計"
            >
                <b-card-text>
                    <ul>
                        <li v-bind:key="index" v-for="(item, index) in nowStatus.status">
                            {{item}}
                        </li>
                    </ul>
                </b-card-text>
            </b-card>
            <b-card>
                <b-card-body body-class="container">
                    <div class="row justify-content-between">
                        <div class="col-4"><h3>即時訪客清單</h3></div>
                        <div class="col-4">
                            <b-pagination
                                    v-model="metaData.middleTable.currentPage"
                                    :total-rows="nowStatus.visitors.length"
                                    per-page= "5"
                                    aria-controls="stu-record"
                            />
                        </div>
                    </div>
                </b-card-body>
                <b-card-text>
                    <b-table
                            id="stu-record"
                            :current-page="metaData.middleTable.currentPage"
                            :items="nowStatus.visitors"
                            :fields="metaData.middleTable.fields"
                            per-page="5"
                    >
                        <template v-slot:cell(action)="row">
                            <b-link @click="findVisitor({uuid: row.item.uuid})">選取</b-link>
                        </template>
                    </b-table>
                </b-card-text>
            </b-card>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "COVID-19-Guard",
        data: function () {
            return {
                studentInfo: {
                    name: "",
                    liveInfo: [
                    ],
                    record: [
                    ],
                    uuid: ""
                },
                corn: null,
                metaData: {
                    location: "",
                    liftTable: {
                        sortBy: "enter_time",
                        sortDesc: true,
                        currentPage: 1,
                        perPage: 5,
                        fields: [
                            {
                                key: 'location',
                                sortable: true,
                                label: "地點"
                            },
                            {
                                key: 'id_number',
                                label: "證件號"
                            },
                            {
                                key: 'enter_time',
                                label: '進入時間',
                                sortable: true
                            },
                            {
                                key: 'exit_time',
                                label: "離開時間",
                                sortable: true
                            },
                            {
                                key: 'action',
                                label: '操作'
                            }
                        ],
                    },
                    middleTable: {
                        currentPage: 1,
                        perPage: 5,
                        fields: [
                            {
                                key: 'place',
                                sortable: true,
                                label: "地點"
                            },
                            {
                                key: 'identityNumber',
                                label: "證件號"
                            },
                            {
                                key: 'enterTime',
                                label: '進入時間',
                                sortable: true
                            },
                            {
                                key: 'student',
                                label: "引路人",
                                sortable: true
                            },
                            {
                                key: 'action',
                                label: '操作'
                            }
                        ],
                    }
                },
                fields: {
                    liftUp: "",
                    liftDown: "",
                    middleUp: ""
                },
                visitorInfo: {
                    who: {
                        "name": "",
                        "uuid": ""
                    },
                    location: "",
                    enter_time: null,
                    exit_time: null,
                    uuid: null
                },
                showMiddleVisitorNotFound: false,
                showLeftAlert: false,
                showStudentNotFound: false,
                nowStatus: {
                    status: [],
                    visitors: []
                }
            }
        },
        methods:{
            queryStudent(focus=true){
                this.showStudentNotFound = false;
                axios.get('/api/misc/visitor_l', { params: { keyWord: this.fields.liftUp } })
                .then((response) => {
                    this.studentInfo = response.data;
                    if(focus){
                        this.$nextTick(function(){
                            this.$refs.leftDown.$el.focus();
                        });
                    }
                }).catch((error)=>{
                    if(error.response){
                        //window.console.log(error);
                        if(error.response.status === 404){
                            //window.console.log("我進來了");
                            this.showStudentNotFound = true;
                            this.fields.liftUp = '';
                            this.studentInfo = {
                                name: "",
                                liveInfo: [
                                ],
                                record: [
                                ],
                                uuid: ""
                            };
                        }else {
                            window.alert("系統錯誤，請稍後再試");
                            window.console.log(error);
                        }
                    }else {
                        window.console.log(error);
                    }
                })
            },
            addVisitor(){
                if(!this.isLocationSet){
                    window.alert("請設置本場所所在之區域");
                }else if(this.fields.liftUp === this.fields.liftDown){
                    this.fields.liftDown = '';
                    this.$refs.leftDown.$el.focus();
                    this.showLeftAlert = true;
                }else{
                    this.showLeftAlert = false;
                    axios.post('/api/misc/visitor_l', {
                        stuUuid: this.studentInfo.uuid,
                        visitor: this.fields.liftDown,
                        location: this.metaData.location
                    }).then((response)=>{
                        this.studentInfo.add = response.data;
                        this.queryStudent(false);
                        this.fields.liftUp = '';
                        this.$refs.leftUp.$el.focus();
                    }).catch((error)=>{
                        if(error.response.status === 409){
                            window.alert("該證件號碼重複");
                        }else {
                            window.alert(error.response.data.msg);
                        }
                    })
                    this.fields.liftDown = '';
                }

            },
            findVisitor(params){
                axios.get('/api/misc/visitor_m', { params: params })
                .then((response)=>{
                    this.showMiddleVisitorNotFound = false;
                    this.visitorInfo = response.data;
                    this.fields.middleUp = '';
                }).catch((error)=>{
                    if(error.response){
                        if(error.response.status === 404){
                            this.showMiddleVisitorNotFound = true;
                        }
                    }else{
                        window.console.log(error);
                    }

                })
                this.fields.middleUp = '';
            },
            letVisitorExit(){
                axios.post('/api/misc/visitor_m', {vid: this.visitorInfo.uuid})
                .then((response)=>{
                    this.visitorInfo = response.data;
                }).catch((error)=>{
                    if(error.response.status === 404){
                        this.showMiddleVisitorNotFound = true;
                    }else if(error.response.status === 409){
                        window.alert("使用者並不在宿舍裡面");
                    }else if(error.response.status === 500){
                        window.alert("無法解析，請稍後再試");
                    }
                    window.console.log(error);
                })
            },
            getStatus(){
                axios.get('/api/misc/visitor_status')
                .then((response)=>{
                    this.nowStatus = response.data;
                }).catch((error)=>{
                    window.alert("不明錯誤發生，請稍後再試試看");
                    window.console.log(error);
                })
            }
        },
        computed:{
            isLocationSet: function () {
                return !(this.metaData.location === "")
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                this.corn = window.setInterval(() => {
                    this.getStatus();
                },10000);
            })
        },
        beforeDestroy: function () {
            if(this.corn !== null){
                window.clearInterval(this.corn);
            }
        }
    }
</script>

<style scoped>

</style>